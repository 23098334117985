<template>
  <el-dialog
    :title="'Sửa thông tin tài khoản'"
    :visible.sync="dialogVisible"
    width="900px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    top="10vh"
  >
    <el-form
      label-width="120px"
      :model="dataInput"
      label-position="left"
      :rules="rules"
      ref="dataInput"
    >
      <el-row>
        <div>
          <el-form-item label="Tên ngân hàng" style="width: 400px" prop="bankName">
            <el-input v-model="dataInput.bankName" />
          </el-form-item>
          <el-form-item
            label="Số tài khoản"
            style="width: 400px; float: right; margin-right: 20px; margin-top: -62px"
            prop="accountNumber"
          >
            <el-input v-model="dataInput.accountNumber" />
          </el-form-item>

          <el-form-item label="Chủ tài khoản" style="width: 400px" prop="fullName">
            <el-input v-model="dataInput.fullName" />
          </el-form-item>

          <el-form-item
            label="Chi nhánh"
            style="width: 400px; float: right; margin-right: 20px; margin-top: -62px"
          >
            <el-input v-model="dataInput.branch" />
          </el-form-item>

          <el-form-item label="Mô tả" style="width: 100%" prop="content">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Nhập nội dung mô tả"
              v-model="dataInput.description"
            >
            </el-input>
          </el-form-item>
          <el-form-item v-if="dataInput.urlImgQrCode === '' || showUpload === true" label="QR Code">
            <el-upload
                action="/api/upload/"
                list-type="picture-card"
                :auto-upload="false"
                :file="dataInput.multipartFile"
                :on-change="handleChangePicture"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                ref="upload"
                accept="image/jpeg,image/png"
                :disabled = "showFileList === false"
            >
              <div v-if="showFileList === true" >
                <img  class="avatar" />
                <i slot="default" class="el-icon-plus" style="font-style: normal"
                > Thêm mã QR Code</i
                >
              </div>
              <div slot="file" slot-scope="{ file }">
                <span style="background: orange; padding: 5px">Mới</span>
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt/>
                <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  @click="handleRemove"
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
              </div>
            </el-upload>
            <div>
              <el-button
                  v-if="dataInput.urlImgQrCode !== ''"
                  @click = "backPicture()"
                  type="info"
                  style="
                    width: 100px;
                    color: white;
                    font-size: 15px;
                    text-align: center;
                    float: left;
                  "
              >Hủy</el-button
              >
            </div>
            <el-dialog width="30%" :visible.sync="dialogVisibleImg">
              <img width="100%" :src="dialogImageUrl" alt/>
            </el-dialog>
          </el-form-item>
          <el-form-item v-else label="QR Code">
            <el-tooltip content="Xem ảnh" placement="top">
              <el-image
                  @click="handleShowDetailPicture()"
                  :src="
              dataInput.urlImgQrCode.replace(
              '/album/',
              '/album/thumbnail_'
              )
              "
                  style="width: 160px; height: 160px"
                  class="el-image-opacity"
              ></el-image>
            </el-tooltip>
            <div>
             <span>
               <el-tooltip content="Sửa" placement="top">
                <el-button
                    @click = "updatePicture()"
                    type="success"
                    style="
                    width: 60px;
                    color: white;
                    font-size: 15px;
                    text-align: center;
                    float: left;
                  "
                ><i class="el-icon-edit"></i></el-button
                >
               </el-tooltip>
                <span class="el-upload-list__item-actions">
                  <el-tooltip content="Xóa" placement="top">
                  <el-button
                      @click = "deletePicture()"
                      type="danger"
                      class="el-upload-list__item-preview"
                      style="float: left; height: 40.5px; width: 60px; margin-left: 41px"
                  >
                    <i style="color: white" class="el-icon-delete-solid"></i>
                  </el-button>
                  </el-tooltip>
                </span>
              </span>
            </div>
          </el-form-item>
          <el-dialog width="30%" :visible.sync="dialogVisiblePicture">
            <img
                width="100%"
                :src="dataInput.urlImgQrCode"
                alt
            />
          </el-dialog>
        </div>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" size="medium" @click="closeDialog()">
        <i class="el-icon-circle-close" />
        <span>{{ $t("button.close") }}</span>
      </el-button>
      <el-button
        type="success"
        size="medium"
        :loading="loadingButton"
        mini
        @click="submitForm()"
      >
        <i class="el-icon-circle-check" />
        <span>{{ $t("button.save") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import CashInternalService from "@/services/CashInternalService";

export default {
  components: {},
  props: {
    dialogVisible: null,
  },

  data() {
    return {
      dataInput: {
        id:0,
        fullName: "",
        accountNumber: "",
        bankName: "",
        branch: "",
        description: "",
        multipartFile: null,
        urlImgQrCode: "",
      },
      checkedKeepPhoto: true,
      disabled: false,
      loadingButton: false,
      dialogVisibleImg: false,
      dialogVisiblePicture: false,
      showUpload: false,
      showFileList: true,
      dialogImageUrl: "",
      rules: {
        bankName: [
          {
            required: true,
            message: "Tên ngân hàng không được để trống",
            trigger: "blur",
          },
        ],
        accountNumber: [
          {
            required: true,
            message: "Số tài khoản không được để trống",
            trigger: "blur",
          },
        ],
        fullName: [
          {
            required: true,
            message: "Chủ tài khoản không được để trống",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    // dataInput: {
    //   deep: true,
    // },
  },
  computed: {},
  methods: {
    closeDialog() {
      this.handleClear()
      this.$emit("dialog-close");
      setTimeout(() => {
        this.loadingButton = false;
        this.$refs["dataInput"].resetFields();
      }, 300);
    },
    handleShowDetailPicture() {
      this.dialogVisiblePicture = true;
    },
    updatePicture(){
      this.showUpload = true;
      this.showFileList = true;
      this.disabled = false
    },
    deletePicture(){
      this.dataInput.urlImgQrCode = "";
      this.showUpload = true;
      this.checkedKeepPhoto = false;
    },
    backPicture(){
      this.showUpload = false;
      this.checkedKeepPhoto = true
      this.dataInput.multipartFile = null
    },
    handleChangePicture(file) {
      this.dataInput.multipartFile = file;
      this.showFileList = false;
      this.checkedKeepPhoto = false
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error("Ảnh phải có dạng JPG hoặc PNG!");
        this.handleRemove(file);
      }
      if (!isLt5M) {
        this.$message.error("Ảnh không thể vượt quá 5MB");
        this.handleRemove(file);
      }

      return isJPG && isLt5M;
    },

    handleRemove() {
      this.showFileList = true;
      this.$refs.upload.clearFiles();
      this.dataInput.multipartFile = null
      this.checkedKeepPhoto = true
    },

    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    submitForm() {
      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          if (this.dataInput.multipartFile !==null){
            formData.append("multipartFile", this.dataInput.multipartFile.raw);
          }
          formData.append("fullName", this.dataInput.fullName);
          formData.append("accountNumber", this.dataInput.accountNumber);
          formData.append("bankName", this.dataInput.bankName);
          formData.append("branch", this.dataInput.branch);
          formData.append("description", this.dataInput.description);
          formData.append("id", this.dataInput.id);
          formData.append("checkedKeepPhoto", this.checkedKeepPhoto);
          this.loadingButton = true;
          CashInternalService.updatBankInfo(formData)
            .then((resp) => {
              this.$message({
                message: resp.data.message,
                type: "success",
              });
              setTimeout(() => {
                this.closeDialog();
              }, 500);
            })
            .catch((err) => {
              let messageText =
                err.response.status == 403
                  ? this.$permissionDenied
                  : err.response.data.message;
              this.$message({
                message: messageText,
                type: "error",
              });
            })
            .finally(() => {
              setTimeout(() => {
                this.loadingButton = false;
              }, 500);
            });
        } else {
          console.log("error validate data!");
        }
      });
    },
    getDataInitial(id) {
      CashInternalService.getByIdBank(id)
        .then((resp) => {
          this.dataInput.urlImgQrCode = resp.data.data.urlImgQrCode
          this.dataInput.id = resp.data.data.id
          // this.dataInput.urlImgQrCode = "https://upload.onekids.edu.vn/1/2022/T6/album/1656315264715_1_1656315267544465_compressed5622083935092004261.jpg"
          this.dataInput.fullName = resp.data.data.fullName
          this.dataInput.accountNumber = resp.data.data.accountNumber
          this.dataInput.bankName = resp.data.data.bankName
          this.dataInput.branch = resp.data.data.branch
          this.dataInput.description = resp.data.data.description
          this.dataInput.multipartFile = null
          if (this.dataInput.urlImgQrCode !== ''){
            this.checkedKeepPhoto = true
          }else {
            this.checkedKeepPhoto = false
          }
        })
        .catch((err) => {
          this.$message({
            message: err.response.data.message,
            type: "error",
          });
        });
    },

    handleClear(){
      this.dialogVisiblePicture = false;
      this.showUpload = false;
      this.showFileList = true;
      this.dialogVisibleImg = false;
      this.disabled = false;
      this.dialogImageUrl = ""
      this.dialogVisibleImg = false;
      this.dataInput.fullName = ""
      this.dataInput.accountNumber = ""
      this.dataInput.bankName = ""
      this.dataInput.branch = ""
      this.dataInput.description = ""
      this.dataInput.multipartFile = null
      this.dataInput.urlImgQrCode = ""
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}
/deep/.el-dialog__body {
  padding-bottom: 5px;
}
/deep/.el-input.is-disabled .el-input__inner {
  color: #606266;
  background: #e7e9ec;
  border: 1px solid #dcdfe6;
}
.el-input-number {
  width: 100%;
}

.el-image-opacity:hover {
  opacity: 5 !important;
  border: 1px solid #409eff;
}
.el-image-opacity {
  cursor: pointer;
  opacity: 5 !important;
  border: 1px solid grey;
}
/deep/ .el-upload--picture-card {
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px dashed #ffffff; /* Default border */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
}
</style>
